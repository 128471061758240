import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    setApplicationDataDetails,
    setApplicationLoginDetails,
} from "../../actions/setactiondetails";
import { connect } from "react-redux";

function Sidebar(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [active, setActive] = useState("");
    const handleLogout = () => {
        props.setOnApplicationLoginDetails("");
        props.setOnApplicationDataDetails("");
        navigate("/admission/application");
    };

    useEffect(() => {
        if (location.pathname === '/admission/application/undergraduate')
            setActive('dashboard')
        else if (location.pathname === '/admission/application/undergraduate/personal-information')
            setActive('info')
        else if (location.pathname === '/admission/application/undergraduate/secondary-school-result')
            setActive('result')
        else if (location.pathname === '/admission/application/undergraduate/guardian-information')
            setActive('guardian')
        else if (location.pathname === '/admission/application/undergraduate/documents-uploads')
            setActive('document')
        else if (location.pathname === '/admission/application/undergraduate/document-preview')
            setActive('preview')
        else if (location.pathname === '/admission/application/undergraduate/jamb-result')
            setActive('jamb')
        else
            setActive('dashboard')
    }, [location])

    return (
        <div className="col-lg-3">
            <div className="widget">
                <div className="widget-title">
                    <h4>Welcome, {props.userData !== undefined ? props.userData.FirstName : ""}</h4>
                </div>
                <div className="widget-categories">
                    <ul className="list-unstyled list-style mb-0">
                        <li className={active === 'dashboard' ? 'btn btn-info' : ''}>
                            <Link className="d-flex" to={"/admission/application/dashboard"}>
                                <span className={active === 'dashboard' ? 'text-white' : ''}>Dashboard </span>
                                {active !== 'dashboard' && <i className="fas fa-chevron-right text-right ml-auto text-danger" />}
                            </Link>
                            {active !== 'dashboard' &&
                                <hr style={{ padding: "0px", margin: "5px" }} />}
                        </li>

                        <li className={active === 'info' ? 'btn btn-info' : ''}>
                            <Link className="d-flex" style={{ pointerEvents: 'none' }} to={"/admission/application/undergraduate/personal-information"}>
                                <span className={active === 'info' ? 'text-white' : ''}>Personal Information</span>
                                {active !== 'info' && <i className="fas fa-chevron-right text-right ml-auto text-danger" />}
                            </Link>
                            {active !== 'info' &&
                                <hr style={{ padding: "0px", margin: "5px" }} />}
                        </li>
                        <li className={active === 'result' ? 'btn btn-info' : ''}>
                            <Link className="d-flex" style={{ pointerEvents: 'none' }} to={"/admission/application/undergraduate/secondary-school-result"}>
                                <span className={active === 'result' ? 'text-white' : ''}>O/A'Level Results</span>
                                {active !== 'result' && <i className="fas fa-chevron-right text-right ml-auto text-danger" />}
                            </Link>
                            {active !== 'result' &&
                                <hr style={{ padding: "0px", margin: "5px" }} />}
                        </li>
                        <li className={active === 'jamb' ? 'btn btn-info' : ''}>
                            <Link className="d-flex" style={{ pointerEvents: 'none' }} to={"/admission/application/undergraduate/jamb-result"}>
                                <span className={active === 'jamb' ? 'text-white' : ''}>Jamb Result</span>
                                {active !== 'jamb' && <i className="fas fa-chevron-right text-right ml-auto text-danger" />}
                            </Link>
                            {active !== 'jamb' &&
                                <hr style={{ padding: "0px", margin: "5px" }} />}
                        </li>
                        <li className={active === 'guardian' ? 'btn btn-info' : ''}>
                            <Link className="d-flex" style={{ pointerEvents: 'none' }} to={"/admission/application/undergraduate/guardian-information"} >
                                <span className={active === 'guardian' ? 'text-white' : ''}>Guardian Information</span>
                                {active !== 'guardian' &&
                                    <i className="fas fa-chevron-right text-right ml-auto text-danger" />}
                            </Link>
                            {active !== 'guardian' &&
                                <hr style={{ padding: "0px", margin: "5px" }} />}

                        </li>
                        <li className={active === 'document' ? 'btn btn-info' : ''}>
                            <Link className="d-flex" style={{ pointerEvents: 'none' }} to={"/admission/application/undergraduate/documents-uploads"} >
                                <span className={active === 'document' ? 'text-white' : ''}>Document Upload</span>
                                {active !== 'document' && <i className="fas fa-chevron-right text-right ml-auto text-danger" />}
                            </Link>
                            {active !== 'document' &&
                                <hr style={{ padding: "0px", margin: "5px" }} />}
                        </li>

                        <li className={active === 'preview' ? 'btn btn-info' : ''}>
                            <Link className="d-flex" style={{ pointerEvents: 'none' }} to={"/admission/application/undergraduate/document-preview"} >
                                <span className={active === 'preview' ? 'text-white' : ''}>Preview</span>
                                {active !== 'preview' && <i className="fas fa-chevron-right text-right ml-auto text-danger" />}
                            </Link>
                            {active !== 'preview' &&
                                <hr style={{ padding: "0px", margin: "5px" }} />}
                        </li>
                    </ul>
                </div>
            </div>

            <div className="widget row pb-4 mb-5 mt-4">
                <div className="col-lg-12 col-sm-12">
                    <button className="btn btn-sm btn-info" onClick={handleLogout}>
                        Logout
                    </button>
                </div>
            </div>
        </div>
    );
}
const mapDispatchToProps = (dispatch) => {
    return {
        setOnApplicationLoginDetails: (p) => {
            dispatch(setApplicationLoginDetails(p));
        },
        setOnApplicationDataDetails: (p) => {
            dispatch(setApplicationDataDetails(p));
        },
    };
};

export default connect(null, mapDispatchToProps)(Sidebar);
