import { projectCode } from "../resources/url";
import { setHomepageContentDetails } from "../actions/setactiondetails";

export const FacultyListDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_FACULTY_LIST_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const FacultyDataDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_FACULTY_DATA_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const DepartmentListDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case `SET_${projectCode}_DEPARTMENT_LIST_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const DepartmentDataDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case `SET_${projectCode}_DEPARTMENT_DATA_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const CourseListDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_COURSE_LIST_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const CourseDataDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case `SET_${projectCode}_COURSE_DATA_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const StaffListDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case `SET_${projectCode}_STAFF_LIST_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const StaffProfileDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case `SET_${projectCode}_STAFF_PROFILE_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const ModuleListDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case `SET_${projectCode}_MODULE_LIST_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const NewsDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case `SET_${projectCode}_NEWS_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const EventDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case `SET_${projectCode}_EVENT_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const ApplicationLoginDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case `SET_${projectCode}_APPLICATION_LOGIN_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const ApplicationDataDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case `SET_${projectCode}_APPLICATION_DATA_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const GeneralDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case `SET_${projectCode}_GENERAL_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const PublicationDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_PUBLICATION_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const UniversityInPressDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case `SET_${projectCode}_UNIVERISITY_IN_PRESS_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const HRStaffListDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_HR_STAFF_LIST_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const HRStaffDataDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_HR_STAFF_DATA_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const HomepageContentDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_HOMEPAGE_CONTENT_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const ApplicationRequirementDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_APPLICATION_REQUIREMENT_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const SliderDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_HOME_SLIDER_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const PartnersDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_PARTNERS_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};
