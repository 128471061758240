import React from "react";
import { Spinner } from "react-bootstrap";

function DataLoader() {
  return (
   <div className="space-ptb text-center" style={{
       position: 'relative',
       top: '50%',
       left: '40%',
       // margin: '-25px 0 0 -25px'
   }}>
       <Spinner
           animation="grow"
           variant="secondary"
           role="status"
           style={{
               height: "100px",
               width: "100px",
               display: "block",
               color: "#e32845",
           }}
       />
   </div>
  );
}

export default DataLoader;
