import React from "react";
import { Link } from "react-router-dom";


const ComingSoon = (props) => {

    return (
        <>
            <section className="coming-soon vh-100 py-0" style={{ backgroundImage: 'url(images/bg/02.jpg)' }}>
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-lg-8 col-md-10 align-self-center">
                            <h1 className="text-dark">Coming Soon</h1>
                            <p className="text-dark">we would be done in a bit, please check back later...</p>
                            <div className="countdown-wrapper">
                                <div className="countdown">
                                    <span className="text-dark days">00</span>
                                    <p className="text-dark days_ref">days</p>
                                </div>
                                <div className="countdown">
                                    <span className="text-dark hours">00</span>
                                    <p className="text-dark hours_ref">hours</p>
                                </div>
                                <div className="countdown">
                                    <span className="text-dark minutes">00</span>
                                    <p className="text-dark minutes_ref">minutes</p>
                                </div>
                                <div className="countdown">
                                    <span className="text-dark seconds">00</span>
                                    <p className="text-dark seconds_ref">seconds</p>
                                </div>
                            </div>
                            <h4 className="text-dark">We will notify you when site is ready:</h4>
                            <div className="row mb-5 mt-5 pb-5">
                                <div className="col-lg-9">
                                    <div className="mt-1">
                                        <form>
                                            <Link to="/"  className="btn btn-info">
                                            
                                            Back to homepage</Link>
                                            
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ComingSoon;
