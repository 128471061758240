import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setApplicationLoginDetails, setGeneralDetails } from "../../actions/setactiondetails";
import { serverLink } from "../../resources/url";
import Sidebar from "./sidebar";

const Selection = (props) => {
  const navigate = useNavigate();
  const [userData] = useState(props.applicationLogin);
  const [appDetail, setAppDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selection, setSelection] = useState({
    ApplicationID: props.applicationData.EntryID,
    hostel: "",
    admissionType: "",
  });

  const [shouldSave, setShouldSave] = useState(false);

  const handleChange = (e) => {
    setSelection({ ...selection, [e.target.id]: e.target.value });
    setShouldSave(true);
  };

  const getData = async () => {
    await axios
      .get(
        `${serverLink}application/application-select/${props.applicationData.EntryID}`
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          setSelection({
            ...selection,
            hostel: response.data.data[0].IsHostelRequired,
            admissionType: response.data.data[0].AdmissionType
          })
          setShouldSave(true);
        }
        setAppDetail(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    for (let value in selection) {
      if (selection.hasOwnProperty(value) && value !== "hostel") {
        if (selection[value] === "") {
          toast.error(`${value} can't be empty`);
          return false;
        }
      }
    }

    if (shouldSave) {
      toast.info(`Submitting... Please wait!`);
      if (selection.hostel === "") setSelection({ ...selection, hostel: "No" });
      await axios
        .patch(`${serverLink}application/update-applicant`, selection)
        .then((res) => {
          if (res.data.message === "success") {
            allApplicationData();
            toast.success(`Information Submitted Successfully`);
            onNext();
          } else
            toast.error(
              `Something went wrong. Please check your connection and try again!`
            );
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
    }
  };

  const onPrevious = () => {
    navigate("/admission/application/undergraduate");
  };

  const onNext = () => {
    navigate("/admission/application/undergraduate/personal-information");
  };

  async function allApplicationData() {
    axios
      .get(
        `${serverLink}application/ug/application/data/${props.applicationData.EntryID}`
      )
      .then((response) => {
        // console.log(response)
        props.setOnGeneralDetails(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="container">

      <div className="row">
        <Sidebar userData={userData} />
        <div className="col-lg-9">

          <form onSubmit={handleSubmit} className="login bg-white shadow p-5">
            <h3>Start Application</h3>
            <hr />
            <h5>Select Options</h5>
            <div className="row mt-3">
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="Gender">Do you want a hostel </label>
                  <select
                    id="hostel"
                    className="form-control"
                    onChange={handleChange}
                    value={selection.hostel}
                  >
                    <option value="">Select Option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6 mb-5">
                <div className="form-group">
                  <label htmlFor="admissionType">
                    Admission Type <span className="text-danger">*</span>
                  </label>
                  <select
                    id="admissionType"
                    className="form-control"
                    onChange={handleChange}
                    value={selection.admissionType}
                  >
                    <option value="">Select Option</option>
                    <option value="UTME">UTME</option>
                    <option value="Direct Entry">Direct Entry</option>
                    <option value="Transfer">Transfer</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="filters-group mb-lg-4 text-center">
                <button className="btn btn-dark  active" onClick={onPrevious}> Previous</button>
                <button className="btn btn-dark w-50  active" type="submit">  Save and Continue</button>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    applicationLogin: state.ApplicationLoginDetails,
    applicant_data: state.GeneralDetails,
    applicationData: state.ApplicationDataDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnApplicationLoginDetails: (p) => {
      dispatch(setApplicationLoginDetails(p));
    },
    setOnGeneralDetails: (p) => {
      dispatch(setGeneralDetails(p));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Selection);

