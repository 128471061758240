import React from "react";
import { Link } from "react-router-dom";
import PageName from "../common/pagename/pagename";

export default function NotFound() {
  return (
    <>
      <PageName page={"404 Not Found"} />
      <section className="inner-banner bg-overlay-black-70">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12">
              <div className="text-center">
                <h1 className="text-white">404 Error</h1>
                <p  className="text-white">The page you are looking for might have been removed or had its name changed or is temporarily unavailable.</p>
              </div>
              <div className="d-flex justify-content-center ">
                <ol className="breadcrumb mb-0 p-0">
                  <li className="breadcrumb-item"><Link to={"/"}>Home</Link></li>
                  <li className="breadcrumb-item active"><span>404 Error</span></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}
