import React from "react";

const Banner =(props)=>{


    return(
        <section className="inner-banner bg-overlay-black-70"  style={{backgroundImage: `url("${props.Image}")`, height:`${props.height}`}}>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-md-12">
                <div className="text-center">
                  <h1 className="text-white">{props.Title}</h1>
                </div>  
                <div className="d-flex justify-content-center ">
                  <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item"><a href="">{props.breadcrumb[0]}</a></li>
                    <li className="breadcrumb-item active"><span>{props.breadcrumb[1]}</span></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
    )
}

export default Banner;