import React from "react";

export default function PageTitle (props) {
    return <div className="page-banner-area bg-2" >
        <div className="container">
            <div className="page-banner-content">
                <h1>{props.title}</h1>
                    {
                        props.breadCrumbs.length > 0 ?
                        <ul>
                            {
                                props.breadCrumbs.map((item, key) => {
                                  return  <li key={key}>{item}</li>
                                })
                            }

                        </ul> : ""
                    }
            </div>
        </div>
    </div>
}
