import { projectCode, serverLink } from "../resources/url";
import axios from "axios";
import {
  LIST_FACULTY_FAIL,
  LIST_FACULTY_REQUEST,
  LIST_FACULTY_SUCCESS,
} from "../constants/constants";

export const setFacultyListDetails = (p) => {
  return {
    type: `SET_${projectCode}_FACULTY_LIST_DETAILS`,
    payload: p,
  };
};

export const setFacultyDataDetails = (p) => {
  return {
    type: `SET_${projectCode}_FACULTY_DATA_DETAILS`,
    payload: p,
  };
};

export const setDepartmentListDetails = (p) => {
  return {
    type: `SET_${projectCode}_DEPARTMENT_LIST_DETAILS`,
    payload: p,
  };
};

export const setDepartmentDataDetails = (p) => {
  return {
    type: `SET_${projectCode}_DEPARTMENT_DATA_DETAILS`,
    payload: p,
  };
};

export const setCourseListDetails = (p) => {
  return {
    type: `SET_${projectCode}_COURSE_LIST_DETAILS`,
    payload: p,
  };
};

export const setCourseDataDetails = (p) => {
  return {
    type: `SET_${projectCode}_COURSE_DATA_DETAILS`,
    payload: p,
  };
};

export const setStaffListDetails = (p) => {
  return {
    type: `SET_${projectCode}_STAFF_LIST_DETAILS`,
    payload: p,
  };
};

export const setStaffProfileDetails = (p) => {
  return {
    type: `SET_${projectCode}_STAFF_PROFILE_DETAILS`,
    payload: p,
  };
};

export const setModuleListDetails = (p) => {
  return {
    type: `SET_${projectCode}_MODULE_LIST_DETAILS`,
    payload: p,
  };
};

export const setNewsDetails = (p) => {
  return {
    type: `SET_${projectCode}_NEWS_DETAILS`,
    payload: p,
  };
};

export const setEventDetails = (p) => {
  return {
    type: `SET_${projectCode}_EVENT_DETAILS`,
    payload: p,
  };
};

export const setApplicationLoginDetails = (p) => {
  return {
    type: `SET_${projectCode}_APPLICATION_LOGIN_DETAILS`,
    payload: p,
  };
};

export const setApplicationDataDetails = (p) => {
  return {
    type: `SET_${projectCode}_APPLICATION_DATA_DETAILS`,
    payload: p,
  };
};

export const setGeneralDetails = (p) => {
  return {
    type: `SET_${projectCode}_GENERAL_DETAILS`,
    payload: p,
  };
};

export const setPublicationDetails = (p) => {
  return {
    type: `SET_${projectCode}_PUBLICATION_DETAILS`,
    payload: p,
  };
};

export const setUniversityInPressDetails = (p) => {
  return {
    type: `SET_${projectCode}_UNIVERISITY_IN_PRESS_DETAILS`,
    payload: p,
  };
};

export const listAcademicFaculty = () => async (dispatch) => {
  try {
    dispatch({ type: LIST_FACULTY_REQUEST });
    const { data } = await axios.get(`${serverLink}academic/faculty/all/`);
    dispatch({
      type: LIST_FACULTY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LIST_FACULTY_FAIL,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const setHRStaffListDetails = (p) => {
  return {
    type: `SET_${projectCode}_HR_STAFF_LIST_DETAILS`,
    payload: p,
  };
};

export const setHRStaffDataDetails = (p) => {
  return {
    type: `SET_${projectCode}_HR_STAFF_DATA_DETAILS`,
    payload: p,
  };
};

export const setHomepageContentDetails = (p) => {
  return {
    type: `SET_${projectCode}_HOMEPAGE_CONTENT_DETAILS`,
    payload: p,
  };
};

export const setApplicationRequirementDetails = (p) => {
  return {
    type: `SET_${projectCode}_APPLICATION_REQUIREMENT_DETAILS`,
    payload: p,
  };
};

export const setHomeSliderDetails = (p) => {
  return {
    type: `SET_${projectCode}_HOME_SLIDER_DETAILS`,
    payload: p,
  };
};

export const setPartnersDetails = (p) => {
  return {
    type: `SET_${projectCode}_PARTNERS_DETAILS`,
    payload: p,
  };
};
