import React from "react";

function ExamType(props) {
    return (
        <>
            <h5>{`Sitting ${props.index}`}</h5>
            <div className="row w-100">
                <div className="col-6">
                    <div className="form-group">
                        <label htmlFor={`ExamType${props.index}`}>
                            Exam Type <span className="text-danger">*</span>
                        </label>
                        <select
                            id={`ExamType${props.index}`}
                            className="form-control"
                            onChange={props.onChange}
                        >
                            <option value="">Select Option</option>
                            <option value="WAEC"> WAEC</option>
                            <option value="NECO"> NECO</option>
                            <option value="NABTEB"> NABTEB</option>
                            <option value="NBAIS"> NBAIS</option>
                            <option value="GCE"> GCE</option>
                        </select>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label htmlFor={`ExamCentre${props.index}`}>
                            Exam Centre <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            id={`ExamCentre${props.index}`}
                            className="form-control"
                            placeholder="Examination Centre"
                            // required
                            onChange={props.onChange}
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label htmlFor={`ExamYear${props.index}`}>
                            Exam Year <span className="text-danger">*</span>
                        </label>
                        <input
                            type="number"
                            id={`ExamYear${props.index}`}
                            name={`ExamYear${props.index}`}
                            min={1980}
                            max={new Date().getFullYear()}
                            className="form-control"
                            placeholder="Examination Year"
                            // required
                            onChange={props.onChange}
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label htmlFor={`ExamsNumber${props.index}`}>
                            Exam Number <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            id={`ExamsNumber${props.index}`}
                            className="form-control"
                            placeholder="Examination Number"
                            // required
                            onChange={props.onChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ExamType;