import axios from "axios";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setGeneralDetails } from "../../actions/setactiondetails";
import {serverLink, shortCode} from "../../resources/url";
import Sidebar from "./sidebar";

const DocumentUpload = (props) => {
    const navigate = useNavigate();
    const [userData] = useState(props.applicationLogin);
    const [addDocument, setAddDocument] = useState(false);
    const addForm = () => {
        setAddDocument(true);
        setRegister({
            ApplicationID: props.applicationData.EntryID,
            DocumentType: "",
            file: "",
        });
    };
    const [register, setRegister] = useState({
        ApplicationID: props.applicationData.EntryID,
        DocumentType: "",
        file: "",
    });

    const allApplicationData = async () => {
        await axios
            .get(
                `${serverLink}application/ug/application/data/${props.applicationData.EntryID}`
            )
            .then((response) => {
                props.setOnGeneralDetails(response.data);
                // setJambData(response.data.jamb);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const onSubmit = async (sendData) => {
        toast.info(`Submitting... Please wait!`);

        if (register.DocumentType === "") {
            toast.error("please select document type")
            return 
        }
        if (register.file === "") {
            toast.error("please select a file")
            return 
        }
        const fdt = new FormData();
        fdt.append("ApplicationID", register.ApplicationID);
        fdt.append("DocumentType", register.DocumentType);
        fdt.append("photo", register.file);
        fdt.append("code", shortCode);

        axios.post(`${serverLink}application/upload-documents`, fdt)
            .then((res) => {
                if (res.data.message === "success") {
                    allApplicationData();
                    toast.success(`Document Uploaded`);
                    setAddDocument(false);
                } else {
                    toast.error(`Something went wrong submitting your document!`);
                }
            })
            .catch((error) => {
                console.log("Error", error);
            });
    };

    const onEdit = (e) => {
        const id = e.target.id
        const value = id === "file" ? e.target.files[0] : e.target.value

        setRegister({
            ...register,
            [id]: value,
        });
    };

    const onPrevious = () => {
        navigate("/admission/application/undergraduate/guardian-information");
    };

    const onNext = () => {
        navigate("/admission/application/undergraduate/document-preview");
    };
    const deleteItem = async (id, image) => {
        if (id) {
            toast.info(`Deleting... Please wait!`);
            await axios
                .delete(`${serverLink}application/pg/document/delete/${id}`)
                .then((res) => {
                    if (res.data.message === "success") {
                        allApplicationData();
                        toast.success(`Deleted`);
                    } else {
                        toast.error(
                            `Something went wrong. Please check your connection and try again!`
                        );
                    }
                })
                .catch((error) => {
                    console.log("NETWORK ERROR", error);
                });
        }
    };

    const handleFile = (url) => {
        const sendData = {
            ...register,
            FileName: url
        }

        if (url !== '') {
            onSubmit(sendData)
        }

    }

    return (
        <>
            <div className="container">
                <div className="row mb-5 pb-5">
                    <Sidebar userData={userData} />

                    <div className="col-lg-9">
                        <form className="login bg-white shadow p-5">
                            <h5>Guardian Information</h5>
                            <div className="col-md-12">
                                <hr />

                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="DocumentType">Document Type</label>
                                            <select
                                                id="DocumentType"
                                                name="DocumentType"
                                                className="form-control"
                                                onChange={onEdit}
                                            >
                                                <option value="">Select Option</option>
                                                {props.appRequirement ? (
                                                    <>
                                                        {props.appRequirement.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.DocumentName}>
                                                                    {item.DocumentName}
                                                                </option>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            {/* <label htmlFor="Designation">File <strong className="text-danger" ><small> &emsp;(File size must not exceed 1mb)</small></strong></label> */}

                                            {/* {
                                                register.DocumentType !== '' ?
                                                    <SimpleFileUpload
                                                        maxFileSize={1}
                                                        apiKey={simpleFileUploadAPIKey}
                                                        tag="alansar-application"
                                                        onSuccess={handleFile}
                                                        preview="false"
                                                        width="100%"
                                                        height="100"
                                                    /> : <div className="pt-5 ">Select Document Type to Upload</div>
                                            } */}

                                            {
                                                register.DocumentType !== '' ?
                                                    <>
                                                        <label htmlFor="file">File <strong className="text-danger" ><small> &emsp;(File size must not exceed 1mb)</small></strong></label>
                                                        <input
                                                            type="file"
                                                            accept=".pdf, .jpg, .png, .jpeg"
                                                            id="file"
                                                            name="file"
                                                            className="form-control"
                                                            placeholder="File Name"
                                                            required
                                                            onChange={onEdit}
                                                        />
                                                        <span className="badge bg-secondary text-white">
                                                            Only .pdf, .jpg, .png, .jpeg are allowed
                                                        </span></>
                                                    : <div className="pt-4 ">Select Document Type to Upload</div>
                                            }


                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <button className="btn btn-md btn-secondary w-25" onClick={onSubmit} type="button">
                                            Save
                                        </button>
                                    </div>
                                </div>
                                <br />
                                <h4>Required Documents</h4>
                                <hr />
                                <ul>
                                    {props.appRequirement && (
                                        <>
                                            {props.appRequirement.map((item, index) => {
                                                if (item.IsRequired === 1) {
                                                    return <li key={index}>{item.DocumentName}</li>;
                                                }
                                            })}
                                        </>
                                    )}
                                </ul>
                                <div className="table-responsive">
                                    {props.applicant_data.documents.length > 0 ? (
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Document Type</th>
                                                    <th>File</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.applicant_data.documents.map((item, index) => (

                                                    <tr key={index}>
                                                        <td>{item.DocumentType}</td>
                                                        <td>
                                                            <a
                                                                target="_blank"
                                                                referrerPolicy="no-referrer"
                                                                href={item.FileName !== null ?
                                                                    item.FileName.includes("simplefileupload.com") ? item.FileName :
                                                                        `${serverLink}public/uploads/${shortCode}/application/document/${item.FileName}` : ""}
                                                            >
                                                                <i className="fa fa-file" />
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <Button
                                                                variant="danger"
                                                                onClick={() =>
                                                                    deleteItem(item.EntryID, item.FileName)
                                                                }
                                                            >
                                                                <i
                                                                    className="fa fa-trash"
                                                                    style={{ fontsize: "30px" }}
                                                                />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div className="alert alert-info">
                                            There is no record. Click on Add Document
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-12">
                                    <div className="filters-group mb-lg-4 text-center">
                                        <button className="btn btn-dark  active" onClick={onPrevious}> Previous</button>
                                        <button className="btn btn-dark w-50 active" onClick={onNext}>  Save and Continue</button>
                                    </div>
                                </div>
                                <div>
                                </div>
                            </div></form>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        applicationLogin: state.ApplicationLoginDetails,
        applicant_data: state.GeneralDetails,
        appRequirement: state.ApplicationRequirementDetails,
        applicationData: state.ApplicationDataDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentUpload);
