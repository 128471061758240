import React, { useEffect, useState } from "react";
import {Link, useLocation} from "react-router-dom";
import "./navigationbar.css";
import Logo from "../../../images/logo.png";
import { setFacultyListDetails } from "../../../actions/setactiondetails";
import { connect } from "react-redux";
import axios from "axios";
import {
  projectEmail,
  projectFacebook, projectInstagram, projectLinkedIn, projectLogo,
  projectName,
  projectPhone, projectStaffURL, projectStudentURL,
  projectTwitter, projectURL,
  serverLink
} from "../../../resources/url";

function NavigationBar(props) {
  const location = useLocation();


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
  });
  }, [location.pathname]);

  const getFacultyList = async () => {
    await axios
      .get(`${serverLink}academics/faculty/academic/list`)
      .then((response) => {
        props.setOnFacultyListDetails(response.data);
      })
      .catch((error) => {
        console.log("NETWORK ERROR", error);
      });
  };
  useEffect(() => {
    getFacultyList();
  }, []);

  return (
      <header className="header header-sticky">
        <div className="topbar bg-dark py-3 d-none d-lg-flex">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-none d-lg-flex align-items-center text-center">
                  <div className="mr-3 d-inline-block">
                    <a className="text-white" href={`mailto:${projectEmail}`}><i className="far fa-envelope mr-2"/><span>Email:</span> {projectEmail}</a>
                  </div>
                  <div className="mr-auto d-inline-block">
                    <a className="text-white" href={`tel:${projectPhone}`}><i className="fas fa-phone-alt mr-2"/><span>Call</span> {projectPhone}</a>
                  </div>
                  <div className="mr-3 d-inline-block">
                    <a className="text-white" href="mailto:gethelp@university.com"><i className="far fa-clock mr-2"/><span>Opening:</span> 09:00am
                      - 5:00pm</a>
                  </div>
                  <div className="social-icon">
                    <ul className="social-icon">
                      <li>
                        <a href={projectFacebook}><i className="fab fa-facebook-f"/></a>
                      </li>
                      <li>
                        <a href={projectTwitter}><i className="fab fa-twitter"/></a>
                      </li>
                      <li>
                        <a href={projectLinkedIn}><i className="fab fa-linkedin-in"/></a>
                      </li>
                      <li>
                        <a href={projectInstagram}><i className="fab fa-instagram"/></a>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-main py-3">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-lg-flex align-items-center">
                  <a href="/">
                    <img src={projectLogo} alt={projectName} width={50}/>
                  </a>


                  <nav className="navbar navbar-expand-lg">

                    <button className="navbar-toggler" id="ToggleNavBar" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"/>
                    </button>

                    <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                      <ul className="navbar-nav">
                        <li className="nav-item"><Link to={"/"} className="nav-link">Home</Link></li>

                        <li className="nav-item"><a href={`${projectURL}about-us`} className="nav-link">About Us</a></li>
                        <li className="nav-item"><a href={`${projectURL}course-finder`} className="nav-link">Course Finder</a></li>
                        <li className="nav-item"><a href={`${projectURL}fees`} className="nav-link">Fees</a></li>
                        <li className="nav-item"><a href={`${projectURL}academic-calender`} className="nav-link">Academic Calender</a></li>
                        <li className="nav-item"><a href={`${projectURL}contact-us`} className="nav-link">Contact Us</a></li>
                      </ul>
                    </div>

                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
  );
}

const mapStateToProps = (state) => {
  return {
    facultyList: state.FacultyListDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnFacultyListDetails: (p) => {
      dispatch(setFacultyListDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
